<template>
    <admin-dashboard-layout>
        <v-data-table
            :loading="loading"
            loading-text="Loading... Please wait"
            :headers="headers"
            :items="reviews"
            :items-per-page="15"
            class="elevation-1"
        >
            <template v-slot:item.text="{ item }">
                <v-row class="cursor-pointer text--blue">
                    <v-col @click="handleClickName(item)">
                        {{ item.text }}
                    </v-col>
                </v-row>
            </template>
            <template v-slot:item.created_at="{ item }">
                {{ new Date(item.created_at).toLocaleString() }}
            </template>
            <template v-slot:item.actions="{ item }">
                <review-delete-dialog @deleted="handleRemoveReview" :review="item" />
            </template>
        </v-data-table>
    </admin-dashboard-layout>
</template>

<script>
import Review from '../../../models/Review';
import AdminDashboardLayout from "../../../layouts/AdminDashboardLayout";
import ReviewDeleteDialog from "../../../components/dialogs/ReviewDeleteDialog";


export default {
    name: "reviews-index",
    components: {AdminDashboardLayout,ReviewDeleteDialog},
    data: () => (
        {
            loading: false,
            headers: [
                {
                    text: '# ID',
                    align: 'start',
                    sortable: false,
                    value: 'id',
                },
                {text: 'Text', value: 'text'},
                {text: 'Created at', value: 'created_at'},
                {text: 'Actions', value: 'actions'},
            ],
        }
    ),
    methods: {
        handleClickName(review) {
            this.$router.push({name: 'admin.reviews.show', params: {id: review.id}})
        },
        handleRemoveReview(id) {
            this.reviews = this.$_.filter(this.reviews, review => review.id !== id);
        }
    },
    async mounted() {
        this.loading = true
        this.reviews = await Review.get()
        this.loading = false;
    },
}
</script>

<style scoped>
.cursor-pointer {
    cursor: pointer;
}

.text--blue {
    color: #0d47a1;
}

.text--blue:hover {
    text-decoration: underline;
}
</style>
